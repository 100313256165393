<template>
  <v-menu
    ref="toolMenu"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        ref="test"
        class="mx-3"
        color="secondary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          class="mr-2"
          small
        >
          mdi-wrench
        </v-icon>
        <template>
          Tools
        </template>
      </v-btn>
    </template>
    <v-card @click.native.stop>
      <v-list>
        <template v-for="(tool, index) in tools">
          <v-list-item :key="index">
            <v-btn
              text
              @click="showTool = tool"
            >
              <v-icon
                v-if="tool.icon"
                class="mr-2"
                small
              >
                {{ tool.icon }}
              </v-icon>
              {{ tool.name }}
            </v-btn>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
    <v-dialog
      v-model="showModal"
      persistent
      scrollable
      :width="width"
      class="button-minimal-size"
    >
      <v-card
        v-if="showTool"
        :loading="childrenLoading"
      >
        <v-card-title :class="childrenLoading ? ['pt-1', 'px-0'] : ['pt-0', 'px-0']">
          <v-toolbar :color="colorScheme">
            Tool ::
            {{ showTool.name }}
            <v-icon
              v-if="showTool.icon"
              class="mx-1"
              color="black"
              small
            >
              {{ showTool.icon }}
            </v-icon>
            <v-spacer />
            <v-btn
              :loading="childrenLoading"
              :disabled="childrenDisabled"
              :color="colorScheme"
              @click="showTool = null"
            >
              Tool schließen
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <component
            :is="showTool.component"
            @isLoading="childrenLoading = $event"
            @isDisabled="childrenDisabled = $event"
          ></component>
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>
  </v-menu>
</template>
<script>
export default {
  name: 'ToolWidget',
  components: {},
  props: {
    tools: { type: Array, required: true }
  },
  data: () => ({
    showTool: null,
    showModal: false,
    childrenLoading: false,
    childrenDisabled: false
  }),
  computed: {
    width: function () {
      return this.showTool?.width ?? '75%';
    },
    colorScheme: function () {
      return this.showTool?.colorScheme ?? 'primary';
    }
  },
  watch: {
    showTool: function (newValue) {
      this.showModal = newValue !== null;
      if (this.showModal) {
        // simulate click event to hide the tools menu
        this.$refs.test.click(new MouseEvent('click'));
      }
    }
  },
  methods: {}
};
</script>
